@import "../variables.scss";

.grid {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  > * {
    // padding: 0.5rem 0.5rem;
    margin-bottom: 1rem;
  }
  max-width: 1000px;
  // > *:nth-child(odd) {
  //   font-weight: bold;
  //   padding: 0.25rem 0;
  //   margin-right: 2rem;
  // }
  // > *:nth-child(even) {
  //   // background-color: white;
  //   padding: 0.5rem 0.5rem;
  //   max-width: 1000px;
  // }

  // input[type="text"],
  // input[type="number"] {
  //   width: 100%;
  // }
}

@media only screen and (max-width: 500px) {
  .grid {
    grid-template-columns: 1fr;
    > *:nth-child(odd) {
      margin-bottom: 0;
    }
  }
}
