@import "../variables.scss";

.toolbar {
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;;
  margin-top: -$grid-padding;
  margin-left: -$grid-padding;
  margin-right: -$grid-padding;
  margin-bottom: $grid-padding;
  background-color: $background-color;
  border-bottom: 1px solid #aaa;
  z-index: 10;
}

.top {
  display: flex;
  align-items: center;

  > *:not(:last-child) {
    margin-right: 0.2rem;
  }

  h2 {
    font-size: 0.8rem;
    font-weight: normal;
    padding: 0.5rem;
    text-transform: uppercase;
  }
}

.expanded {
  padding: 0.5rem;
  background-color: #ccc;
}