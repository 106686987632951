@import "../variables.scss";

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.55);
  z-index: 1000;
}

.modalContent {
  position: fixed;
  background-color: $background-color-dark;
  color: $text-color-dark;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 75vh;
  min-height: 200px;
  margin: 1rem 0.5rem;
  padding: 0 0.5rem;
}

.clipboard {
  cursor: pointer;
  padding: 0 0.25rem;
}

.clipboardIcon {
  fill: $text-color-dark;
  pointer-events: none;
  width: 1.4rem;
}

$scrollbar-color: white;
$scrollbar-thumb-color: #ccc;
.listWrapper {
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
  scrollbar-color: $scrollbar-thumb-color $scrollbar-color;
}

.listWrapper::-webkit-scrollbar {
  width: 0.3rem;
  background-color: $scrollbar-color;
}

.listWrapper::-webkit-scrollbar-thumb {
  background: $scrollbar-thumb-color;
}

.list {
  min-width: 250px;
  border-collapse: collapse;
  td {
    padding: 0.3rem;
    white-space: nowrap;
  }
  thead td {
    position: sticky;
    top: 0;
    background-color: $background-color-dark;
    font-weight: bold;
  }
}

.empty {
  white-space: nowrap;
  font-size: 2rem;
  padding: 3rem;
}
