@import "../variables.scss";

.form {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  > * {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
  }
}
