@import "../variables.scss";
@import "../../node_modules/@vipscasino/shared-frontend/src/styles/promotion.scss";

%previews {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  
  .previews {
    @extend %previews;
  }
  
  .previewsDisabled {
    @extend %previews;
    opacity: 0.2;
  }
  
  %preview {
    padding: 0.7rem 1rem;
    cursor: pointer;
  }
  
  .preview {
    @extend %preview;
  }
  
  .previewDisabled {
    @extend %preview;
    cursor: auto;
  }
  
  .previewIcon {
    pointer-events: none;
  }
  
  .translationActions {
    display: flex;  
    align-items: center;
  }
    
  .translationAction {
    display: flex;
    align-items: center;
    cursor: pointer;
    border: 1px solid #ccc;
    outline: none;
    margin-right: 0.3rem;
    margin-bottom: 0.1rem;
    padding: 0.3rem;
    border-radius: 0;
    color: black;
    background-color: white;
    text-decoration: none;
    font-size: inherit;
  
    > *:nth-child(1) {
      width: 1.2rem;
      margin-right: 0.1rem;
    }
  }
  
  .translationAction:hover {
    background-color: #eee;
  }