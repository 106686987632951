@import "../variables.scss";


.button {
  margin-bottom: 1rem;
  padding: 0.5rem;
}

.result {
  padding-top: 1rem;
  font-size: 18px;
  color: #555;
}

.error {
  color: red;
}

.multiErrors {
  padding-top: 0.5rem;
  font-size: 18px;
  color: red;
}

.progress {
  margin-top: 1rem;
  position: relative;
  background-color: #ddd;
  border: 1px solid #ccc;
}

.progressText {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.progressBar {
  width: 0%;
  height: 2.5rem;
  background-color: green;
}