@import "../variables.scss";

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  .linkContainer {
    margin-bottom: 0.5rem;
  }
  a {
    color: #555;
    text-decoration: underline;
    text-transform: uppercase;
    font-size: 0.8rem;
    cursor: pointer;
  }
  .markdownContainer {
    background-color: $site-background-color;
    color: $site-text-color;
    // border: 2px dashed rgb(187, 186, 186);
    // padding: 0.5rem;
    width: 100%;
  }
}

.editableContent {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  textarea {
    flex: 1;
    padding: 0.5rem;
    resize: none;
  }
  .markdownContainer {
    background-color: $site-background-color;
    color: $site-text-color;
    display: flex;
    flex: 1;
    flex-direction: column;
    // border: 2px dashed rgb(187, 186, 186);
    border-left: none;
    // padding: 0.5rem;
  }
}

.markdownGuide {
  display: block;
  color: gray;
  text-align: right;
  // margin-top: 0.2rem;
}
