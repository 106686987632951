@import "../variables.scss";

.login {
  background-color: $background-color-dark;
  color: $text-color-dark;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);

  header {
    margin-bottom: 1rem;
  }

  main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
  }

  footer {
    font-size: 0.8rem;
    // font-family: monospace;
  }
}
