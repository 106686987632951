@import "../variables.scss";

.content {
  display: flex;
  flex-direction: column;
}

.image {
  width: 100%;
  height: auto;
}