@import "../variables.scss";
@import "../../node_modules/@vipscasino/shared-frontend/src/styles/promotion.scss";

$contentWidth: 710px;
$desktopMargin: 32px;
$scrollbar-color: white;
$scrollbar-thumb-color: #ccc;

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.55);
  z-index: 1000;
}

%modal-content {
  position: fixed;
  background-color: $site-background-color;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  color: $site-text-color;
  text-align: center;
  * {
    font-family: $site-font-family;
  }
  font-size: $site-font-size;
}

.modalContentDesktop {
  @extend %modal-content;
  max-width: $contentWidth + 2 * $desktopMargin;
}

.modalContentMobile {
  @extend %modal-content;
  max-width: $contentWidth;
}

.empty {
  font-size: 2rem;
  padding: 3rem;
}

%content {
  max-width: $contentWidth;
  max-height: 75vh;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
  scrollbar-color: $scrollbar-thumb-color $scrollbar-color;
}

%content::-webkit-scrollbar {
  width: 0.3rem;
  background-color: $scrollbar-color;
}

%content::-webkit-scrollbar-thumb {
  background: $scrollbar-thumb-color;
}

.contentDesktop {
  @extend %content;
  margin: $desktopMargin;
}

.contentMobile {
  @extend %content;
}

%gradient {
  background: $site-background-gradient;
  padding-bottom: 1.5rem;
}

.gradient {
  @extend %gradient;
}

.gradientNoTac {
  @extend %gradient;
  border-bottom-left-radius: $site-background-radius;
  border-bottom-right-radius: $site-background-radius;
}

%image {
  width: 100%;
}

.imageDesktop {
  @extend %image;
  border-radius: 25px;
}

.imageMobile {
  @extend %image;
}

.title {
  margin-top: 1rem;
  text-transform: uppercase;
}

.descDate {
  margin-top: 1rem;
  margin-bottom: 2rem;
  text-transform: uppercase;
  font-size: 0.8rem;
  color: gray;
}

%tac {
  position: relative;
  background-color: $darkgray-color;
}

.tacDesktop {
  @extend %tac;
  border-bottom-left-radius: $site-background-radius;
  border-bottom-right-radius: $site-background-radius;
}

.tacMobile {
  @extend %tac;
}

.tacTrigger {
  text-transform: uppercase;
  padding: 1rem;
  text-align: left;
  cursor: pointer;
}

.tacValid {
  margin: 1rem;
  text-align: left;
}

.tacUpdated {
  padding: 0.5rem 1rem;
  font-size: 0.8rem;
  text-align: right;
  font-style: italic;
}

.buttonWrapper {
  margin-top: 1rem;
}

.button {
  outline: none;
  border-radius: 0.25rem;
  font-size: 1.5rem;
  font-weight: bold;
  padding: 0.5rem 2rem;
  text-transform: uppercase;
  background: linear-gradient(45deg, $site-primary-color-dark 0%, $site-primary-color-light 100%);
  color: black;
  border: none;
}

.gamesPlaceholder {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2rem 0.2rem;
  padding: 0 0.5rem;
  background-color: grey;
  height: 10rem;
  color: black;
  font-size: 1.5rem;
  font-weight: bold;
}

.leaderboardPlaceholder {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: grey;
  margin: 0 25%;
  padding: 0 0.5rem;
  height: 20rem;
  color: black;
  font-size: 1.5rem;
  font-weight: bold;
}