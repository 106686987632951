@import "../../variables.scss";

.main {
  position: relative;
}

.input {
  padding: 0.5rem;
  border: 1px solid #eee !important;
}

.results {
  position: absolute;
  background-color: white;
  // padding: 0 1rem;
  $border: 1px solid #ddd;
  border-left: $border;
  border-right: $border;
  border-bottom: $border;
  z-index: 10;
}

.result {
  cursor: pointer;
}


.result:not(:last-child) {
  border-bottom: 1px solid #eee;
}

.text {
  padding: 1rem 1rem;
  font-size: 1rem;
}

.highlight {
  // background-color: $primary-color;
  text-decoration: underline;
  // font-weight: bold;
}
