.flags {
  white-space: nowrap;
  svg {
    margin: 0 0.3rem;
    $size: 1.2rem;
    width: $size;
    opacity: 0.3;
  }
  svg.selected {
    opacity: 1;
  }
}

.languages {
  white-space: nowrap;
  span {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  div {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0.3rem;
    padding: 0 0.3rem;
    $size: 1.3rem;
    height: $size;
    background-color: pink;
    opacity: 0.3;
    user-select: none;
  }
  div.selected {
    opacity: 1;
  }
}

.id {
  white-space: nowrap;
  text-align: right;
  font-family: "Roboto Mono", monospace;
}

.checked {
  text-align: center;
  font-size: 1.5rem;
  color: green;
}

.partlyChecked {
  text-align: center;
  font-size: 1.5rem;
  color: orange;
}
