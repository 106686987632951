@import "../../commonTable.scss";

.center {
  text-align: center;
}

.gameCount {
  text-decoration: underline;
  cursor: pointer;
}
