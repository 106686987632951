@import "../variables.scss";

.main {
  position: relative;
}

.input {
  padding: 0.5rem;
  border: 1px solid #eee !important;
}

.results {
  position: absolute;
  background-color: white;
  $border: 1px solid #ddd;
  border-left: $border;
  border-right: $border;
  border-bottom: $border;
  z-index: 10;
}

.result {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.result:not(:last-child) {
  border-bottom: 1px solid #eee;
}

.text {
  padding: 1rem 1rem;
  font-size: 1rem;
}

.dimensions {
  padding: 1rem 0 1rem 1rem;
  font-size: 0.9rem;
  color: gray;
}

.highlight {
  text-decoration: underline;
}
