@import "../variables.scss";

.logo {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: $text-color-dark;
  font-size: 1rem;

  svg {
    height: 1.5rem;
    margin-right: 0.5rem;
  }

  h1 {
    text-transform: uppercase;
    font-size: 1.0rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
  }
}

.logoLarge {
  flex-direction: column;
  svg {
    height: 4rem;
    margin-bottom: 1rem;
  }

  h1 {
    font-size: 2rem;
    letter-spacing: 0.1rem;
  }
}