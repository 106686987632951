@import "../variables.scss";

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.content a {
  color: #555;
  text-decoration: underline;
  text-transform: uppercase;
  font-size: 0.8rem;
  cursor: pointer;
  margin-bottom: 0.5rem;
}

.image {
  width: 100%;
  height: auto;
}
