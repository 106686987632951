@import "../variables.scss";

.item {
  display: flex;
  align-items: center;
  cursor: pointer;
  border: none;
  outline: none;
  margin: 0;
  padding: 0.2rem 0.3rem;
  border-radius: 0;
  color: $text-color;
  background-color: $background-color;
  text-decoration: none;
  font-size: inherit;

  > *:nth-child(1) {
    width: 1.2rem;
    margin-right: 0.1rem;
  }
}

.item:hover {
  background-color: #ddd;
}

.active {
  background-color: #ccc;
}

.item:disabled {
  $color: silver;
  color: $color;
  svg {
    fill: $color;
  }
}

.popup {
  position: absolute;
  top: 2rem;
  background-color: #e5e5e5;
  padding: 0.5rem;
}

.notification {
  $color: red;
  color: $color;
  fill: $color;
}
