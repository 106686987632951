@import "../variables.scss";


.home {
  min-height: 100vh;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    "header header"
    "nav main"
    "footer main";
}

.header {
  grid-area: header;
  padding: $grid-padding;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: $background-color-dark;

  .logo {
    text-decoration: none;
  }

  .user {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    div {
      font-size: 0.8rem;
      color: $text-color-dark;
    }
    button {
      font-size: 0.7rem;
      cursor: pointer;
      background-color: transparent;
      padding-top: 0.25rem;
      outline: none;
      border: none;
      font-weight: normal;
      text-decoration: underline;
      text-transform: uppercase;
      color: $text-color-dark;
    }
  }
}

.navContainer {
  background-color: $background-color-dark;
  border-top: 1px solid #20262F;

}

.nav {
  grid-area: nav;
  position: sticky;
  top: 0;
  padding: 0 $grid-padding;
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  text-transform: uppercase;
  // font-size: 0.9rem;
  .link, .subLink {
    // text-decoration: none;
    color: $text-color-dark;
    padding: 0.4rem 0;
  }

  .activeLink {
    color: $primary-color;
  }

  .subLink {
    margin-left: 0.5rem;
  }
}

.main {
  grid-area: main;
  padding: $grid-padding;
  // max-width: 95vw;
}

.footer {
  grid-area: footer;
  background-color: $background-color-dark;
  color: $text-color-dark;
  padding: $grid-padding;
  text-align: center;
  // font-family: monospace;
  font-size: 0.7rem;
}

@media only screen and (max-width: 500px) {
  .home {
    grid-template-columns: auto;
    grid-template-rows: auto auto 1fr auto;
    grid-template-areas:
      "header"
      "nav"
      "main"
      "footer";
  }
  .nav {
    position: static;
    flex-direction: row;
    flex-wrap: wrap;
    white-space: none;
    .link {
      padding: 0.4rem 0.4rem;
    }
  }

  .main {
    max-width: 100vw;
  }
}
