@import "../../commonTable.scss";

.buttons {
  margin-bottom: 0.5rem;
}

.button {
  margin-right: 2rem;
}

.center {
  text-align: center;
}
