@import "../variables.scss";

.content {
  display: flex;
  flex-direction: column;
}

.imageContainer {
  position: relative;
  padding-bottom: 0.5rem;
}

.image {
  width: 100%;
  height: auto;
}

.removeIcon {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.5rem;
  cursor: pointer;
  svg {
    width: 2rem;
    height: 2rem;
  }
}
