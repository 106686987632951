@import "../variables.scss";

.sortableTable {
  border-collapse: collapse;
  td {
    padding: 0.5rem;
    border: 1px solid #ddd;
  }
  thead td {
    font-weight: bold;
    white-space: nowrap;
  }
  td.action {
    border: none;
    button {
      margin: 0 0.3rem;
    }
  }
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.sortable {
  cursor: pointer;
  text-decoration: underline;
}

.sortIcon {
  margin-left: 0.1rem;
  $size: 1rem;
  fill: $text-color;
  width: $size;
  height: $size;
}

$sort-active-color: red;

.sortActive {
  color: $sort-active-color;
}

.sortActive > .sortIcon {
  fill: $sort-active-color !important;
}

.showToggle {
  display: flex;
  align-items: center;
  div {
    margin-right: 0.25rem;
  }
  svg {
    width: 1rem;
  }
}

.results {
  padding: 0.25rem 0;
  font-style: italic;
  color: #888;
}
