@import "../commonTable.scss";

.center {
  text-align: center;
}

.requiredTextInput:not(:valid) {
  background-color: lightcoral;
}

$external-id-padding: 0.2rem;
.externalId {
  padding: 0 $external-id-padding;
}

.externalIdInactive {
  padding: 0 $external-id-padding;
  color: red;
}