@import "../variables.scss";

.label {
  font-weight: bold;
  padding: 0.25rem 0;
  margin-right: 2rem;
}

.children {
  input[type="text"],
  input[type="number"],
  input[type="search"] {
    width: 100%;
    border: none;
    outline: none;
  }
  padding: 0.5rem;
}

.editable {
  background-color: white;
}

.noPadding {
  padding: 0;
}

.dirty{
  $color: #FCE883;
  border: 2px solid $color;
}