@import "../variables.scss";
@import "../../node_modules/@vipscasino/shared-frontend/src/styles/banner.scss";

.translationsDesc {
  text-align: center;
  color: gray;
  font-size: 1.2rem;
  padding-bottom: 0.1rem;
}

.translationsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.translations {
  width: 100%;
}

.previewItem {
  cursor: pointer;
  padding: 0.75rem;
  padding-left: 0.25rem;
}

.previewIcon {
  pointer-events: none;
  width: 2rem;
}

.refLink {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.refEditIcon {
  margin-left: 1rem;
}

.translationActions {
  display: flex;  
  align-items: center;
}

.translationAction {
  display: flex;
  align-items: center;
  cursor: pointer;
  border: 1px solid #ccc;
  outline: none;
  margin-right: 0.3rem;
  margin-bottom: 0.1rem;
  padding: 0.3rem;
  border-radius: 0;
  color: black;
  background-color: white;
  text-decoration: none;
  font-size: inherit;

  > *:nth-child(1) {
    width: 1.2rem;
    margin-right: 0.1rem;
  }
}

.translationAction:hover {
  background-color: #eee;
}
