@import "../variables.scss";

// .filter {
//   display: flex;
//   flex-direction: row;
//   flex-wrap: wrap;

//   > * {
//     display: flex;
//     flex-direction: column;
//     justify-content: flex-start;
//     margin-right: 0.5rem;
//     margin-bottom: 0.5rem;
//   }

//   input,
//   select,
//   button {
//     min-height: 30px;
//   }

//   input {
//     border: 1px #aaa solid;
//   }

//   input[type="text"] {
//     padding: 0 0.2rem;
//     max-width: 30vw;
//   }

//   label {
//     margin-bottom: 0.1rem;
//   }
// }

.dateSpan {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}

// .showAll {
//   padding: 0.25rem 1rem;
//   align-self: flex-start;
// }
