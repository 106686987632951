@import "../variables.scss";

.search {
  border: 1px #aaa solid;
  padding: 0 0.2rem;
  margin: 0.2rem 0;
  margin-left: 0.3rem;
  min-height: 24px;
  max-width: 30vw;
}
