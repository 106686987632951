$background-color: #eee;
$background-color-dark: black; // #0a111a;
$text-color: black;
$text-color-dark: #eee;
$primary-color: #f8d126;
$site-background-color: black; //#0a111a;
$site-background-sub-color: black; // #101b28;
$site-text-color: white;
$site-primary-color-dark: #fcbe0e;
$site-primary-color-light: #f4e43e;
$site-font-family: "Roboto Condensed", sans-serif;
$site-background-gradient-color: #1d1d1d;
$site-background-gradient: linear-gradient($site-background-color, $site-background-gradient-color);
$site-background-radius: 25px;
$site-font-size: 16px;
$grid-padding: 0.5rem;
// $site-width: 1000px;
// $topbar-height: 3rem;
// $bar-border: $background-sub-color 1px solid;
// $box-border:  #333 1px solid;
// $icon-size: 1.8rem;
$mobile-width: 500px;
$banner-height-desktop: 190px;
$banner-width-desktop: 1200px;
$banner-height-mobile: 246px;
$banner-width-mobile: 640px;
