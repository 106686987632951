@import "../variables.scss";
@import "../../node_modules/@vipscasino/shared-frontend/src/styles/game.scss";

.incomplete {
  margin: 1rem 0;
  color: red;
  font-size: 1.25rem;
}

.descriptionInfo {
  padding-left: 0.8rem;
  li {
    padding: 0.15rem 0;
  }
}

.extenalProviderName {
  margin-left: 1rem;
  font-style: italic;
}

.error {
  padding: 1rem;
  border: 1px solid red;
  background-color: lightpink;
  margin-bottom: 1rem;
}

.inputWithNewValue {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  div {
    text-decoration: underline;
    cursor: pointer;
  }
}

.fetchData {
  button {
    padding: 0.2rem 0.5rem;
  }
  input {
    padding: 0.2rem 0.5rem;
    margin-right: 0.5rem;
  }
}

.translationActions {
  display: flex;
  align-items: center;
}

.translationAction {
  display: flex;
  align-items: center;
  cursor: pointer;
  border: 1px solid #ccc;
  outline: none;
  margin-right: 0.3rem;
  margin-bottom: 0.1rem;
  padding: 0.3rem;
  border-radius: 0;
  color: black;
  background-color: white;
  text-decoration: none;
  font-size: inherit;

  > *:nth-child(1) {
    width: 1.2rem;
    margin-right: 0.1rem;
  }
}

.translationAction:hover {
  background-color: #eee;
}

.external {
  position: relative;
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  > * {
    margin-top: 0.5rem;
  }
  max-width: 1000px;
  margin-top: 0.5rem;
  border-top: 1px solid;
}

.externalItemLabel {
  font-weight: bold;
  margin-right: 2rem;
}

.externalAdd {
  display: flex;
  flex-direction: row;
  align-items: center;
  input {
    max-width: 11rem;
  }
}

.externalAddButton {
  display: flex;
  align-items: center;
  cursor: pointer;
  border: 1px solid black;
  outline: none;
  padding: 0.1rem;
  padding-right: 0.4rem;
  margin-left: 0.5rem;
  border-radius: 0;
  color: black;
  background-color: white;
  text-decoration: none;
  font-size: inherit;

  > *:nth-child(1) {
    width: 1.2rem;
    margin-right: 0.1rem;
  }
}

.externalAddButton:hover {
  background-color: #eee;
}

.externalAddButton:disabled {
  $color: silver;
  color: $color;
  border: 1px solid $color;
  svg {
    fill: $color;
  }
}

.externalRemove {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.5rem;
  margin: 0;
  cursor: pointer;
  svg {
    width: 2rem;
    height: 2rem;
  }
}
