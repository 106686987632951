@import "../../node_modules/@vipscasino/shared-frontend/src/styles/promotion.scss";
@import "../commonTable.scss";

.preview {
  display: flex;
  align-items: center;
  justify-content: center;
}

.platformItem {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 0.35rem;
}

.platformIcon {
  pointer-events: none;
  width: 1rem;
}
