@import "../variables.scss";
@import "../../node_modules/@vipscasino/shared-frontend/src/styles/banner.scss";

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.55);
  z-index: 1000;
}

%modal-content {
  position: fixed;
  background-color: $site-background-color;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  color: $site-text-color;
}

.modalContentDesktop {
  @extend %modal-content;
  max-width: $banner-width-desktop;
}

.modalContentMobile {
  @extend %modal-content;
  max-width: $banner-width-mobile;
}

.mainDesktop {
  $margin: 3rem;
  margin-top: $margin;
  margin-bottom: $margin;
}

.mainMobile {
  $margin: 1rem;
  margin-top: $margin;
  margin-bottom: $margin;
}

%outer-container {
  position: relative;
  background-color: $site-background-sub-color;
}

.outerContainerDesktop {
  @extend %outer-container;
  padding-top: $banner-height-desktop / $banner-width-desktop * 100%;
}

.outerContainerMobile {
  @extend %outer-container;
  padding-top: $banner-height-mobile / $banner-width-mobile * 100%;
}

.innerContainer {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}

.content {
  position: relative;
  overflow: hidden;
}

.image {
  width: 100%;
  display: block;
}

.overlay {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  margin: 0.1rem 0.6rem;
  * {
    font-family: $site-font-family;
  }
}

%button {
  cursor: pointer;
  outline: none;
  border-radius: 0.25rem;
  font-size: 1.5rem;
  font-weight: bold;
  padding: 0.5rem 2rem;
  text-transform: uppercase;
  background: linear-gradient(
    45deg,
    $site-primary-color-dark 0%,
    $site-primary-color-light 100%
  );
  color: black;
  border: none;
}

.standaloneButton {
  @extend %button;
  margin: 0.5rem 0;
}

.groupedButton {
  @extend %button;
  margin: 0.1rem 0 0.5rem 0;
}

.smallButton {
  font-size: 0.9rem;
  padding: 0.5rem 1rem;
}
