@import "../variables.scss";

.item {
  label {
    margin-bottom: 0.1rem;
  }

  input,
  select,
  button {
    min-height: 30px;
  }

  input[type="text"] {
    border: 1px #aaa solid;
    padding: 0 0.2rem;
    max-width: 30vw;
  }
}
