@import "../variables.scss";

.item {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 0.25rem;
}

.icon {
  pointer-events: none;
  width: 1rem;
}
