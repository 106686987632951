@import "../variables.scss";

.chip {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.25rem 0.5rem;
  margin: 0.2rem 0;
  margin-right: 0.5rem;
  background-color: $primary-color;
  border-radius: 1rem;

  .text {
    label {
      opacity: 0.7;
      font-size: 0.6rem;
      text-transform: uppercase;
    }
    div {
      padding-right: 0.5rem;
    }
  }
  svg {
    width: 1rem;
    cursor: pointer;
  }
}
