@import "../variables.scss";

$bottom-margin: 0.75rem;

.countries,
.languages {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: $bottom-margin;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    width: 100%;
    border-radius: 0;
    padding: 0;
    border: none;
    border-bottom: 1px solid black;
    outline: none;
  }
  button.empty {
    background-color: rgba(172, 172, 172, 0.2);
    svg {
      opacity: 0.2;
    }
    div {
      color: rgba(0, 0, 0, 0.2);
    }
  }
  button.selected {
    border: 1px solid black;
    border-bottom: none;
  }
  button:first-child {
    border-left: none;
  }
  button:last-child {
    border-right: none;
  }
  button.invalid {
    border: 2px solid red;
  }
}

.countries svg {
  cursor: pointer;
  $size: 3rem;
  width: $size;
  padding: 0.5rem 0;
}

@media only screen and (max-width: 1000px) {
  .countries svg {
    width: 1.7rem;
  }
}

.languages div {
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  cursor: pointer;
  $size: 3rem;
  width: $size;
  height: $size;
  margin: 0.5rem 0;
  font-size: 1.1rem;
}

.children {
  margin: 0.5rem;
  > * {
    margin-bottom: $bottom-margin;
  }
  input {
    padding: 0.25rem;
  }
}
