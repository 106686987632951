@import "../variables.scss";

.back {
  width: 2rem;
  height: 2rem;
  fill: #39a1ff;
  cursor: pointer;
  margin-bottom: 0.5rem;
}

.backDisabled {
  fill: gray;
  cursor: default;
}

.content {
  display: flex;
  flex-direction: row;
}

.contentRight {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.dropzone {
  display: flex;
}

.files {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
}

.placeholder {
  font-size: 1rem;
  color: #555;
}

.loader {
  font-size: 1rem;
  text-align: center;
}

.chips {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.error {
  color: red;
}

.uploadAction {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.uploadAction button {
  // font-size: 12px;
  font-weight: normal;
  cursor: pointer;
  background: #39a1ff;
  color: #fff;
}

.uploadAction button:disabled {
  cursor: default;
}