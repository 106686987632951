@import "../variables.scss";

.main {
  display: flex;
  flex-direction: column;

  textarea {
    width: 100%;
    height: 200px;
    max-width: 800px;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
    padding: 0.5rem;
  }

  label {
    margin-top: 1rem;
    font-style: italic;
  }
}