@import "../variables.scss";

.dropzone {
  min-height: 200px;
  width: 200px;
  background-color: #fff;
  border: 2px dashed rgb(187, 186, 186);
  border-radius: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.highlight {
  background-color: rgb(188, 185, 236);
}

.icon {
  opacity: 0.3;
  height: 64px;
  width: 64px;
}

.fileInput {
  display: none;
}

.info {
  padding-top: 0.5rem;
  text-align: center;
}

@media only screen and (max-width: $mobile-width) {
  .dropzone {
    $size: 30vw;
    height: $size;
    width: $size;
  }
}
